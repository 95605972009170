import { actionTypes } from '../actions/filtersActions';
import { Record, List } from 'immutable';
import { createSelector } from 'reselect';
import isObject from 'lodash/isObject';
import without from 'lodash/without';
import isArray from 'lodash/isArray';
import find from 'lodash/find';
import defaults from 'lodash/defaults';
import includes from 'lodash/includes';
import omit from 'lodash/omit';
import transform from 'lodash/transform';

import { selectors as sectorsSelectors } from './sectorsReducer';
import { selectors as productClassesSelectors } from './productClassesReducer';
import { selectors as itemsSelectors } from './itemsReducer';
import { selectors as appSelectors } from './appReducer';
// import { getHashFromPayload } from '../sagas/itemsSaga';
import { OptionRecord, SelectFilterRecord } from './helpers';
import * as constants from '../constants';
import { SET_FIXED_BRAND } from '../actions/appActions';
import { getLocaleFromLanguageCode } from '../intl-helpers';

// const SimpleFilterRecord = Record({
//     enabled: false,
//     value: false,
// });

const FullTextFilterRecord = Record({
    enabled: true,
    value: '',
    mode: 'legacy',
});

const PriceRangeFilterRecord = Record({
    enabled: true,
    min: 0,
    max: 99999,
    value: [0, 0],
});

const StateRecord = Record({
    [constants.BRAND_FILTER]: SelectFilterRecord({ enabled: true, value: List([]) }),
    //[constants.CATALOG_FILTER]: SelectFilterRecord(),
    [constants.FULL_TEXT_FILTER]: FullTextFilterRecord({
        enabled: true,
        value: '',
    }),
    [constants.LINE_FILTER]: SelectFilterRecord({ value: List([]) }),
    // [constants.OPTIONALS_FILTER]: SimpleFilterRecord(),
    [constants.MODEL_FILTER]: SelectFilterRecord({ value: List([]) }),
    [constants.SERIES_FILTER]: SelectFilterRecord({ value: List([]) }),
    // [constants.REPLACEMENTS_FILTER]: SimpleFilterRecord(),
    // [constants.GOING_OUT_OF_PRODUCTION_FILTER]: SimpleFilterRecord(),
    // [constants.OUT_OF_PRODUCTION_FILTER]: SimpleFilterRecord(),
    [constants.STATUS_FILTER]: SelectFilterRecord({ value: List([]) }),
    [constants.PRICE_RANGE_FILTER]: PriceRangeFilterRecord(),
    [constants.LISTINI_FILTER]: SelectFilterRecord({ value: List([]) }),
    [constants.FUORI_CARTACEO_FILTER]: SelectFilterRecord({
        enabled: true,
        options: List([
            new OptionRecord({
                value: 'true',
                label: 'yes',
                code: 'true',
                extra: null,
            }),
            new OptionRecord({
                value: 'false',
                label: 'no',
                code: 'false',
                extra: null,
            }),
        ]),
    }),
    [constants.ESPORTABILE_PER_CATALOGO_FILTER]: SelectFilterRecord({
        enabled: true,
        options: List([
            new OptionRecord({
                value: 'true',
                label: 'yes',
                code: 'true',
                extra: null,
            }),
            new OptionRecord({
                value: 'false',
                label: 'no',
                code: 'false',
                extra: null,
            }),
        ]),
    }),
});

export const DEFAULT_STATE = StateRecord();

function createOptionObject(key, o) {
    switch (key) {
        case constants.BRAND_FILTER:
        case constants.STATUS_FILTER:
        case constants.LINE_FILTER:
        case constants.MODEL_FILTER:
        case constants.SERIES_FILTER:
            return {
                label: o.description,
                value: o.id,
                code: o.code,
            };
        case constants.LISTINI_FILTER:
            return {
                label: o.descrizione_listino,
                value: o.id,
                code: o.codice_listino,
                extra: {
                    codice_listino: o.codice_listino,
                    codice_listino_prod: o.codice_listino_prod,
                    codice_revisione: o.codice_revisione,
                    data_listino: o.data_listino,
                    data_validita: o.data_validita,
                    data_validita_rev: o.data_validita_rev,
                    descrizione_revisione: o.descrizione_revisione,
                },
            };
        default:
            return o;
    }
}

const multiValueFilters = [
    constants.BRAND_FILTER,
    constants.STATUS_FILTER,
    constants.MODEL_FILTER,
    constants.LINE_FILTER,
    constants.SERIES_FILTER,
    constants.LISTINI_FILTER,
];

function setFilterValue(state, action) {
    const {
        payload: { key, value, extra },
    } = action;
    console.log('action => ', action)
    let newValue = value;

    if (isObject(value) && !isArray(value)) {
        newValue = OptionRecord(value);
    }

    if (multiValueFilters.includes(key)) {
        if (value === null) {
            return state.setIn([key, 'value'], List([]));
        }

        return state.updateIn([key, 'value'], (values) => {
            const idx = values.findIndex((item) => item.value === value.value);

            if (idx === -1) {
                return values.push(newValue);
            }

            return values.delete(idx);
        });
    }

    if (extra && extra.mode) {
        state = state.setIn([key, 'mode'], extra.mode);
    }

    return state.setIn([key, 'value'], newValue);
}

function setFilterOptions(state, action) {
    const {
        payload: { key, options },
    } = action;

    const newOptions = options.map((o) => OptionRecord(createOptionObject(key, o)));

    if (multiValueFilters.includes(key)) {
        const optionIds = newOptions.map((o) => o.value);

        state = state.updateIn([key, 'value'], (values) => {
            return values.filter((value) => {
                return optionIds.includes(value.value);
            });
        });
    }

    return state.setIn([key, 'options'], List(newOptions));
}

function enableFilters(state, action) {
    let result = state;

    action.payload.forEach((key) => {
        result = result.setIn([key, 'enabled'], true);
    });

    return result;
}

function disableFilters(state, action) {
    let result = state;

    action.payload.forEach((key) => {
        result = result.setIn([key, 'enabled'], false);
    });

    return result;
}

function resetFilters(state, action) {
    const res = action.payload.reduce((newState, key) => {
        const oldRecord = newState.get(key);

        let newRecord = oldRecord.clear().set('enabled', oldRecord.enabled);

        if (multiValueFilters.includes(key)) {
            newRecord = newRecord.set('value', List([]));
        }

        return newState.set(key, newRecord);
    }, state);

    return res;
}

function resetAllFilters(state) {
    // Delete local storage filters
    localStorage.removeItem('userFilters');

    return state.withMutations((mutableState) => {
        // Iterate over each key in the state
        Object.keys(state.toJS()).forEach((key) => {
            const oldRecord = state.get(key); // Get the current filter record

            // Log the key and its value before resetting
            // console.log('Resetting filter for key:', key, 'Current value:', oldRecord.toJS());

            let newRecord;

            // Check if it's a multi-value filter, reset 'value' to empty List for multi-value filters
            if (multiValueFilters.includes(key)) {
                newRecord = oldRecord.set('value', List([]));
            } else {
                // For single-value filters, reset to default value if available or log if unsure
                if (oldRecord.has('defaultValue')) {
                    newRecord = oldRecord.set('value', oldRecord.get('defaultValue'));
                } else {
                    if (key === 'priceRange') {
                        newRecord = oldRecord.set('value', [0, 0]) // Keep the same value to avoid breaking the app
                        // console.log('Filter without defaultValue; preserving existing value:', key);
                    } else if (key === 'fullText') {
                        newRecord = oldRecord.set('value', '') // Keep the same value to avoid breaking the app
                        // console.log('Filter without defaultValue; preserving existing value:', key);
                    } else if (key === 'listini') {
                        newRecord = oldRecord.set('value', []) // Keep the same value to avoid breaking the app
                        // console.log('Filter without defaultValue; preserving existing value:', key);
                    } else {
                        newRecord = oldRecord.set('value', null) // Keep the same value to avoid breaking the app
                        // console.log('Filter without defaultValue; preserving existing value:', key);
                    }
                    /*newRecord = oldRecord.set('value', oldRecord.get('value')) // Keep the same value to avoid breaking the app
                    console.log('Filter without defaultValue; preserving existing value:', key);*/
                }
            }

            mutableState.set(key, newRecord); // Update state with reset filter

            // Log the new state after resetting
            // console.log('New filter state for key:', key, 'New value:', newRecord.toJS());
        });
    });
}



function setBrandFilter(state, action) {
    return state.setIn([constants.BRAND_FILTER, 'value'], List([OptionRecord(action.payload)]));
}

function setPriceRange(state, action) {
    const { min, max } = action.payload[0]

    // Check the local storage for price values
    const storedFilters = JSON.parse(localStorage.getItem('userFilters'))

    // If we have price saved in local storage, set the values
    if (storedFilters && storedFilters.prezzo_listino_from !== undefined && storedFilters.prezzo_listino_to !== undefined) {
        return state.updateIn([constants.PRICE_RANGE_FILTER], (filter) => {
            return filter
                .set('min', min)
                .set('max', max)
                .set('value', [min, max])  // Impostiamo il value solo se presente nel localStorage
        })
    }

    // If there isn't price stored in local values, we don't set the values because if the values are setted, the price range choosed is showed
    return state.updateIn([constants.PRICE_RANGE_FILTER], (filter) => {
        return filter.set('min', min).set('max', max)
    })
}


const handlers = {
    [actionTypes.SET_FILTER_VALUE]: setFilterValue,
    [actionTypes.SET_FILTER_OPTIONS]: setFilterOptions,
    [actionTypes.ENABLE_FILTERS]: enableFilters,
    [actionTypes.DISABLE_FILTERS]: disableFilters,
    [actionTypes.RESET_FILTERS]: resetFilters,
    [actionTypes.RESET_ALL_FILTERS]: resetAllFilters,
    [actionTypes.SET_PRICE_RANGE]: setPriceRange,
    [SET_FIXED_BRAND]: setBrandFilter,
};

export default function filtersReducer(state = DEFAULT_STATE, action) {
    if (handlers.hasOwnProperty(action.type)) {
        return handlers[action.type](state, action);
    } else {
        return state;
    }
}

function getSelectsValues(state) {
    let values = {};

    const keys = [
        constants.BRAND_FILTER,
        // constants.CATALOG_FILTER,
        constants.LINE_FILTER,
        constants.MODEL_FILTER,
        constants.SERIES_FILTER,
        constants.STATUS_FILTER,
        constants.LISTINI_FILTER,
    ];

    keys.forEach((key) => {
        //console.log(state.filters[key].toJS());
        values[key] = state.filters[key].value
            ? isArray(state.filters[key].value.toJS())
                ? state.filters[key].value.toJS().map((entry) => entry.value)
                : state.filters[key].value.value
            : null;
    });

    return values;
}

function formatAttributeValue(value, type) {
    switch (type) {
        case constants.ATTRIBUTE_TYPE_NUMERIC:
        default:
            return value;
        case constants.ATTRIBUTE_TYPE_LOGIC:
            return value === 'yes';
        case constants.ATTRIBUTE_TYPE_ALPHANUMERIC:
            return value.toArray();
        case constants.ATTRIBUTE_TYPE_RANGE:
            return value.toArray().map((v) => {
                const [start, end] = v.split('::');
                return [parseFloat(start), parseFloat(end)];
            });
    }
}

function getApiPayload(state, overrides = {}) {
    let payload = {
        per_page: itemsSelectors.getPerPage(state),
        page: itemsSelectors.getCurrentPage(state) + 1,
        include_accessori: true,
        include_ricambi: true,
    };

    const storedFilters = JSON.parse(localStorage.getItem('userFilters'))
    let storedBrands, storedStato, storedLinea, storedModello, storedSerie, storedListini, storedSort, storedSortDirection, storedClassification, storedClassificationPath
    if (storedFilters) {
        storedBrands = storedFilters.sigle_marca
        storedStato = storedFilters.stato
        storedLinea = storedFilters.linea
        storedModello = storedFilters.modello
        storedSerie = storedFilters.serie
        storedListini = storedFilters.listini
        storedSort = storedFilters.sort
        storedSortDirection = storedFilters.sort_direction
        storedClassification = storedFilters.product_tree
        storedClassificationPath = storedFilters.classification_path
    }

    const languageCode = appSelectors.getLanguage(state);

    payload.locale = getLocaleFromLanguageCode(languageCode);

    const brandFilter = selectors.getFilter(state, constants.BRAND_FILTER);

    // console.log('brandFilter from getApiPayload => ', brandFilter.toJS())
    if (brandFilter.enabled && brandFilter.value.size > 0) {
        payload.sigle_marca = brandFilter.value.map((item) => item.code);
    }

    const statusFilter = selectors.getFilter(state, constants.STATUS_FILTER);

    if (statusFilter.enabled && statusFilter.value.size > 0) {
        payload.stato = statusFilter.value.map((item) => item.value)
        // console.log('payload.stato from filtersReducer ', payload.stato.toJS(), statusFilter.toJS())
    } else if (storedStato !== undefined) {
        /*console.log('we are trying to get the stato without losing the brands')
        console.log(payload, storedStato)*/
        // console.log("storedStato from filtersReducer 2 => ", storedStato)
        payload.stato = storedStato
    }

    const lineFilter = selectors.getFilter(state, constants.LINE_FILTER);

    if (lineFilter.enabled && lineFilter.value.size > 0) {
        payload.linea = lineFilter.value.map((item) => item.value);
    } else if (storedLinea !== undefined) {
        payload.linea = storedLinea
    }

    const modelFilter = selectors.getFilter(state, constants.MODEL_FILTER);

    if (modelFilter.enabled && modelFilter.value.size > 0) {
        payload.modello = modelFilter.value.map((item) => item.value);
    } else if (storedModello !== undefined) {
        payload.modello = storedModello
    }

    // return
    const seriesFilter = selectors.getFilter(state, constants.SERIES_FILTER);

    if (seriesFilter.enabled && seriesFilter.value.size > 0) {
        payload.serie = seriesFilter.value.map((item) => item.value);
    } else if (storedSerie !== undefined) {
        payload.serie = storedSerie
    }

    const listiniFilter = selectors.getFilter(state, constants.LISTINI_FILTER);

    if (listiniFilter.enabled && listiniFilter.value.size > 0) {
        payload.listini = listiniFilter.value.map((item) => item.value);
    } else if (storedListini !== undefined) {
        // console.log('we are trying to get the listini without losing the brands')
        // console.log(payload, storedListini)
        payload.listini = storedListini
    }

    // Sort order filter
    const sorting = itemsSelectors.getSorting(state);

    if (sorting.sortBy) {
        payload.sort = sorting.sortBy;
        payload.sort_direction = sorting.sortDirection.toUpperCase();
    }else if (storedSort !== undefined) {
        payload.sort = storedSort
        payload.sort_direction = storedSortDirection.toUpperCase()
    }

    // const optionals = selectors.getFilter(state, constants.OPTIONALS_FILTER);

    // if (optionals.enabled) {
    //     payload.include_accessori = optionals.value;
    // }

    // const replacementsFlag = selectors.getFilter(state, constants.REPLACEMENTS_FILTER);

    // if (replacementsFlag.enabled) {
    //     payload.include_ricambi = replacementsFlag.value;
    // }

    if(storedClassification !== undefined){
        payload.product_tree = storedClassification
    }else{
        payload.product_tree = sectorsSelectors.getFilterValue(state);
    }

    if(storedClassificationPath){
        payload.classification_path = storedClassificationPath
    }

    const node = sectorsSelectors.getSelectedTreeItemRecord(state);
    // console.log('node from filtersReducer => ', Record.isRecord(node) ? node.toJS() : node)
    if (node) {
        node.filterParams.forEach((v, k) => {
            payload[k] = v // It's assign a classification_path to payload but somewhere is settings the key
        })
    }


    /*const idrolabClassification = sectorsSelectors.getIdrolabClassification(state);

    if (idrolabClassification !== null) {
        payload[idrolabClassification.type] = idrolabClassification.id;
    }*/

    const fullTextFilter = selectors.getFilter(state, constants.FULL_TEXT_FILTER);

    if (fullTextFilter.enabled && fullTextFilter.value !== '') {
        if (fullTextFilter.mode === 'legacy') {
            payload.q = fullTextFilter.value;
        } else {
            payload.m_search = fullTextFilter.value;

            if (payload.locale !== 'it_IT') {
                payload.m_search_locale = payload.locale;
            }
        }
    }

    const productClassFilter = productClassesSelectors.getFilter(state);

    if (productClassFilter.enabled && productClassFilter.value) {
        payload.etim_class = productClassFilter.value.value;
    }

    const activeFilters = productClassesSelectors.getActiveFilters(state);
    const attributes = productClassesSelectors.getProductClassAttributes(state);

    const features = activeFilters.map((value, key) => {
        const attribute = attributes.get(key);

        return {
            id: key,
            type: attribute.type,
            value: formatAttributeValue(value, attribute.type),
        };
    });

    if (features.size > 0) {
        payload.etim_features = features.toJS();
    }

    const priceRangeFilter = selectors.getFilter(state, constants.PRICE_RANGE_FILTER);

    if (priceRangeFilter.enabled && priceRangeFilter.value[1] !== 0) {
        payload.prezzo_listino_from = priceRangeFilter.value[0];
        payload.prezzo_listino_to = priceRangeFilter.value[1];
    }

    const fuoriCartaceoFilter = selectors.getFilter(state, constants.FUORI_CARTACEO_FILTER);

    if (fuoriCartaceoFilter.enabled && fuoriCartaceoFilter.value !== null) {
        payload.fuori_listino_cartaceo = fuoriCartaceoFilter.value.value === 'true' ? true : false;
    }

    const esportabilePerCatalogoFilter = selectors.getFilter(
        state,
        constants.ESPORTABILE_PER_CATALOGO_FILTER
    );

    if (esportabilePerCatalogoFilter.enabled && esportabilePerCatalogoFilter.value !== null) {
        payload.export_catalogo =
            esportabilePerCatalogoFilter.value.value === 'true' ? true : false;
    }

    let result = Object.assign({}, payload, overrides);

    // if (currentHash !== previousHash) {
    //     result.page = 1;
    // }

    // console.log(result);

    return result;
}

export const nonFiltersKeys = [
    'per_page',
    'page',
    'locale',
    'product_tree',
    'include_accessori',
    'include_ricambi',
    'properties',
];

const orderKeys = ['sort', 'sort_direction'];

export const selectors = {
    getState(state) {
        return state.filters;
    },
    getFilter(state, key) {
        return state.filters[key];
    },
    getFilters(state) {
        const selects = getSelectsValues(state);

        return {
            ...selects,
            // [constants.OPTIONALS_FILTER]: state.filters[constants.OPTIONALS_FILTER].value,
            // [constants.REPLACEMENTS_FILTER]: state.filters[constants.REPLACEMENTS_FILTER].value,
            [constants.FULL_TEXT_FILTER]: state.filters[constants.FULL_TEXT_FILTER].value,
            // [constants.OUT_OF_PRODUCTION_FILTER]:
            //     state.filters[constants.OUT_OF_PRODUCTION_FILTER].value,
            // [constants.GOING_OUT_OF_PRODUCTION_FILTER]:
            //     state.filters[constants.GOING_OUT_OF_PRODUCTION_FILTER].value
        };
    },
    getActiveFiltersDetails(state) {
        let values = getSelectsValues(state);
        const filters = state.filters.toJS();
        const sectorFilter = sectorsSelectors.getFilter(state);
        const sectorSelected = sectorsSelectors.getSelectedTreeItemRecord(state);
        const isFixedBrand = appSelectors.getIsBrandFixed(state);

        // console.log(sectorFilter.toJS());
        // console.log(sectorSelected ? sectorSelected.toJS() : null);

        filters.productClass = state.productClasses.productClassFilter.toJS();

        let sector = null;

        if (sectorSelected && sectorFilter.toJS().value.code !== 'etim') {
            sector = {
                taxonomy: sectorFilter.toJS().value,
                value: sectorSelected.toJS(),
            };
        }

        // console.log(filters);
        const selectKeys = Object.keys(values);

        const mergedFilters = defaults(values, filters, { sector });

        let result = transform(
            mergedFilters,
            (result, value, key) => {
                // console.log(key, value, isArray(value));

                const filterValues = filters[key]?.options;

                if (value === null) {
                    result[key] = null;
                } else if (isArray(value)) {
                    result[key] = value.map((optionValue) => {
                        return find(filterValues, { value: optionValue });
                    });
                } else if (includes(selectKeys, key)) {
                    result[key] = find(filterValues, { value });
                } else if (key === 'priceRange') {
                    if (value.value[0] !== 0 || value.value[1] !== 0) {
                        result[key] = value.value;
                    } else {
                        result[key] = null;
                    }
                } else if (key === 'productClass') {
                    result[key] = value.value;
                } else if (key === 'fullText') {
                    if (value.value && value.value !== '') {
                        result[key] = value.value;
                    } else {
                        result[key] = null;
                    }
                } else if (key === 'fuoriCartaceo' || key === 'esportabilePerCatalogo') {
                    result[key] = value.value;
                } else {
                    result[key] = value;
                }
                /*else if (key === 'replacements' || key === 'optionals') {
                    result[key] = value.value;
                }*/

                return result;
            },
            {}
        );

        if (isFixedBrand) {
            result = omit(result, ['brand']);
        }

        return result;
    },
    // TODO: questo è giusto che stia qui?
    getApiPayload,
    isAnyFilterActive: createSelector(
        [getApiPayload, appSelectors.getIsBrandFixed],
        (payload, isBrandFixed) => {
            const filters = Object.keys(payload);

            let skipKeys = [];

            // console.log(filters);
            // console.log(without(filters, ...nonFiltersKeys, ...orderKeys, ...skipKeys));

            // if (isBrandFixed) {
            //     skipKeys = ['marca'];
            // }

            return without(filters, ...nonFiltersKeys, ...orderKeys, ...skipKeys).length > 0;
        }
    ),
    isAnyFilterActiveOrOrderer: createSelector([getApiPayload], (payload) => {
        const filters = Object.keys(payload);

        return without(filters, ...nonFiltersKeys).length > 0;
    }),
    hasOnlyBrandFilter: createSelector([getApiPayload], (payload) => {
        const filters = Object.keys(payload);

        if (!filters.includes('sigle_marca')) {
            return false;
        }

        let skipKeys = ['sigle_marca'];

        return without(filters, ...nonFiltersKeys, ...orderKeys, ...skipKeys).length === 0;
    }),
};

// marca: <uuid>
// stato: <uuid>
// serie: <uuid>
// linea: <uuid>
// q: <string>
// settore: <uuid>
// macrofamiglia: <uuid>
// famiglia: <uuid>
// include_accessori: true|false
// include_ricambi: true|false
// etim_class: <uuid>
// classification: etim|idrolab
// etim_features:
//     - id: <uuid>
//       type: alphanumeric
//       value: [<uuid>, <uuid>, ...]
//     - id: <uuid>
//       type: logic
//       value: true|false
//     - id: <uuid>
//       type: numeric
//       value: [<min>, <max>]
//     - id: <uuid>
//       type: range
//       value:
//         - [<float>, <float>]
//         - [<float>, <float>]
//         - ...
//     - ...
// page: <num> (solo per /articoli, verrà ignorata dai filtri)
// per_page: <num> (solo per /articoli, verrà ignorata dai filtri)
// sort: <string> (solo per /articoli, verrà ignorata dai filtri)
//     valori ammessi:
//         prezzo
//         codice_articolo
//         descrizione_articolo
//         modello
//         marca

// sort_direction: ASC|DESC (solo per /articoli, verrà ignorata dai filtri)
