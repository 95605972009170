import { all, call, put, select, takeEvery, fork } from 'redux-saga/effects';
import omit from 'lodash/omit';
// import includes from 'lodash/includes';

import { actions as filtersActions, actions, actionTypes, SET_FILTER_OPTIONS } from '../actions/filtersActions';
import { actions as productClassesActions } from '../actions/productClassesActions';
import { fetchItems } from '../actions/itemsActions';
import { setClassificationValue, toggleTreeItem } from '../actions/sectorsActions';
// import { setTree } from '../actions/sectorsActions';
import { selectors as  selectors } from '../reducers/filtersReducer';
import { selectors as sectorsSelectors } from '../reducers/sectorsReducer';
import { fetchTree } from './sectorsSaga';

import * as api from '../api';
import * as constants from '../constants';

import {
    updateLocalStorage,
    getFilterFromLocalStorage,
    removeFilterFromLocalStorage,
    removeBrandRelatedFilterFromLocalStorage
} from '../utils/LocalStorageFilterUtils'


let isBatchMode = false

// const resetProductClassesKeys = [
//     constants.FULL_TEXT_FILTER,
//     constants.CATALOG_FILTER,
//     constants.LINE_FILTER,
//     constants.MODEL_FILTER,
//     constants.SERIES_FILTER
// ];

export const brandDependentFilters = [
    // constants.CATALOG_FILTER,
    constants.LINE_FILTER,
    constants.MODEL_FILTER,
    constants.SERIES_FILTER,
    constants.LISTINI_FILTER,
];

function* setFilterValueSaga(action) {
    // const { key } = action.payload;

    // if (includes(resetProductClassesKeys, key)) {
    //     yield put(productClassesActions.setProductClassValue(null));
    // }

    if (!isBatchMode) {
        //console.log("isnt batch mode")
        yield put(fetchItems()) // Only fetch items if not in batch mode
    }

    // yield put(fetchItems());
}

function* changeBrandFilterSaga(action) {
    const actualBrandFilter = yield select(selectors.getFilter, constants.BRAND_FILTER);

    const actualBrandFilterValue = actualBrandFilter?.value?.toJS() || [];

    let isValueInFilter = false;

    if (action.payload !== null) {
        isValueInFilter = actualBrandFilterValue.some(
            (filter) => filter?.value === action.payload.value
        )
    }

    // Case 1: The value is not present, add it and reset dependent filters
    if (!isValueInFilter && action.payload !== null) {
        // console.log("The value is not present, resetting brand-dependent filters");
        removeBrandRelatedFilterFromLocalStorage()
        yield put(actions.resetFilters(brandDependentFilters))

        // Case 2: The value is already present and is the only element: deselecting the last active filter
    } else if (actualBrandFilterValue.length === 1 && isValueInFilter || action.payload === null) {
        // console.log("The value is present and is the only one, resetting brand-dependent filters");
        yield put(actions.resetFilters(brandDependentFilters));
        yield put(filtersActions.resetAllFilters());
        yield put(actions.setFilterValue(constants.BRAND_FILTER, null));
        refreshFiltersValues()
        // console.log('apiPayload', action.payload);
        return;

        // Case 3: The value is present but is not the only one, toggle without resetting
    } else {
        // console.log("The value is present, acting as a toggle without reset");
    }

    yield put(actions.setFilterValue(constants.BRAND_FILTER, action.payload));

    const updateFilterAction =
        action.payload === null ? actions.disableFilters : actions.enableFilters;

    yield put(updateFilterAction(brandDependentFilters))
    const apiPayload = yield select(selectors.getApiPayload)

    const shouldFetchFilters = (actualBrandFilterValue.length === 0) ||
        (actualBrandFilterValue.length === 2 && apiPayload.sigle_marca.toJS().length === 1)

    //console.log('actualBrandFilterValue', actualBrandFilterValue, apiPayload.sigle_marca.toJS())

    if (shouldFetchFilters) {
        //console.log('find if we have only one brand', action.payload, actualBrandFilterValue)

        yield all([
            call(fetchFilterData, api.fetchLineeFilter, apiPayload, constants.LINE_FILTER),
            call(fetchFilterData, api.fetchModelliFilter, apiPayload, constants.MODEL_FILTER),
            call(fetchFilterData, api.fetchSeriesFilter, apiPayload, constants.SERIES_FILTER),
            call(fetchFilterData, api.fetchListini, apiPayload, constants.LISTINI_FILTER)
        ])
        // refreshFiltersValues()
    }
}


export function* refreshFiltersValues(apiPayload = null) {
    // console.log('refreshFiltersValues')
    // console.log('apiPayload prima di fetchItemsCustom:', apiPayload);

    if (apiPayload === null) {
        apiPayload = yield select(selectors.getApiPayload);
    }

    //console.log('apiPayload from refreshFiltersValues => ', apiPayload)

    yield fork(fetchTree);

    const brandApiPayload = omit(apiPayload, ['sigle_marca']);

    const storedFilters = JSON.parse(localStorage.getItem('userFilters'))
    const storedStato = storedFilters?.stato || []
    // Define statusPayload
    let statusPayload;

    const [brandsRes, statusRes, priceRangeRes, etimClassRes, listiniRes] = yield all([
        call(api.fetchBrandFilter, brandApiPayload),
        call(api.fetchStatusFilter, apiPayload),
        call(api.fetchPriceRangeFilter, apiPayload),
        call(api.fetchEtimClassFilter, apiPayload),
        call(api.fetchListini, apiPayload),
        // call(api.fetchClassificationTree, apiPayload)
    ]);

    yield put(actions.setFilterOptions(constants.BRAND_FILTER, brandsRes.data));
    yield put(actions.setFilterOptions(constants.STATUS_FILTER, statusRes.data));
    yield put(actions.setPriceRange(priceRangeRes.data));
    yield put(productClassesActions.setProductClassOptions(etimClassRes.data));
    yield put(actions.setFilterOptions(constants.LISTINI_FILTER, listiniRes.data));
    // yield put(setTree(treeRes.data))

    // console.log('apiPayload.sigle_marca is not empty => ', apiPayload);

    const sigleMarca = apiPayload.sigle_marca
    const shouldFetch = sigleMarca !== undefined && (sigleMarca.length === 1 || sigleMarca.toJS().length === 1)

    if (shouldFetch) {
        yield all([
            call(fetchFilterData, api.fetchLineeFilter, apiPayload, constants.LINE_FILTER),
            call(fetchFilterData, api.fetchModelliFilter, apiPayload, constants.MODEL_FILTER),
            call(fetchFilterData, api.fetchSeriesFilter, apiPayload, constants.SERIES_FILTER),
            call(fetchFilterData, api.fetchListini, apiPayload, constants.LISTINI_FILTER)
        ])
    }
}

function* fetchFilterData(apiFn, apiPayload, filterName) {
    try {
        const res = yield call(apiFn, apiPayload);

        yield put(actions.setFilterOptions(filterName, res.data));
    } catch (err) {
        console.error(err);
    }
}

function* loadFiltersFromLocalStorage() {
    const storedFilters = JSON.parse(localStorage.getItem('userFilters'))

    // Check if storedFilters is setted
    if (!storedFilters) {
        return;
    }

    // Check if properties exists
    const storedBrandCodes = storedFilters.sigle_marca ? storedFilters.sigle_marca : []
    const storedStato = storedFilters.stato ? storedFilters.stato : []
    const storedClassification = storedFilters.product_tree ? storedFilters.product_tree : false
    const storedClassificationPath = storedFilters.classification_path ? storedFilters.classification_path : false

    if (
        storedFilters.prezzo_listino_from !== undefined &&
        storedFilters.prezzo_listino_to !== undefined &&
        Number.isInteger(storedFilters.prezzo_listino_from) &&
        Number.isInteger(storedFilters.prezzo_listino_to)
    ) {
        const storedPrices = [
            {
                max: storedFilters.prezzo_listino_to,
                min: storedFilters.prezzo_listino_from
            }
        ];
        //console.log('PREZZI from filtersSaga', storedPrices);
        yield put(actions.setPriceRange(storedPrices));
    }

    const storedSector = storedFilters.settore ? storedFilters.settore : null
    const storedMacrofamiglia = storedFilters.macrofamiglia ? storedFilters.macrofamiglia : null
    const storedFamiglia = storedFilters.famiglia ? storedFilters.famiglia : null

    //yield put(actions.setSelectedItemTree(storedSector));
    // return;
    // Get the current filters from Redux State
    const brandFilters = yield select(selectors.getFilter, constants.BRAND_FILTER)

    // Convert the options into JS array to easily make a check
    const brandOptions = brandFilters.get('options').toJS()

    // For each filter, iterate over saved value e select the existing ones
    const selectedBrands = brandOptions.filter(option => storedBrandCodes.includes(option.code))

    // Start batch mode
    isBatchMode = true;

    if (selectedBrands.length > 0) {
        for (const brand of selectedBrands) {
            yield put(actions.setFilterValue(constants.BRAND_FILTER, brand))
        }
    }

    if (selectedBrands.length === 1) {
        yield all([
            call(fetchFilterData, api.fetchLineeFilter, storedFilters, constants.LINE_FILTER),
            call(fetchFilterData, api.fetchModelliFilter, storedFilters, constants.MODEL_FILTER),
            call(fetchFilterData, api.fetchSeriesFilter, storedFilters, constants.SERIES_FILTER),
            call(fetchFilterData, api.fetchListini, storedFilters, constants.LISTINI_FILTER)
        ])

        yield put (actions.enableFilters(brandDependentFilters))
    }

    // Dispatch actions for classification, if is stored classification we know we need to be sure the filters options
    // are related to such Classification
    // TODO: test if we can avoid a filter reset here
    const defaultSectorsFamilyExist = storedSector || storedMacrofamiglia || storedFamiglia;

    if(storedClassification && !defaultSectorsFamilyExist) {
        const classificationFilter = yield select(sectorsSelectors.getFilter)
        const classificationOptions = classificationFilter.toJS().options; // Suppongo che le opzioni siano in 'options'

        // Search the object that match the locatStorage stored Classification
        const selectedClassification = classificationOptions.find(option => option.value === storedClassification)
        yield put(setClassificationValue(selectedClassification))
    }

    /*if(storedClassificationPath){
        yield put(toggleTreeItem(storedClassificationPath, 0))
    }*/

    // Dispatch actions for sector
    if (storedSector) {
        yield put(toggleTreeItem(storedSector))
    }

    if (storedMacrofamiglia) {
        yield put(toggleTreeItem(storedMacrofamiglia))
    }

    if (storedFamiglia) {
        yield put(toggleTreeItem(storedFamiglia))
    }

    // End batch mode
    isBatchMode = false;
    //refreshFiltersValues()
    // Now trigger refreshFiltersValues once all filters are set
    yield put(fetchItems())

}

export default [
    takeEvery(actionTypes.SET_FILTER_VALUE, setFilterValueSaga),
    takeEvery(actionTypes.CHANGE_BRAND_FILTER_VALUE, changeBrandFilterSaga),
    takeEvery(actionTypes.LOAD_FILTERS_FROM_LOCALSTORAGE, loadFiltersFromLocalStorage),
    takeEvery(actionTypes.REFRESH_FILTERS_VALUES, refreshFiltersValues)

];
