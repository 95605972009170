export const SET_TREE = 'SET_TREE';
export const INIT_PRODUCT_TREE_ROOTS = 'INIT_PRODUCT_TREE_ROOTS';
export const TOGGLE_TREE_ITEM = 'TOGGLE_TREE_ITEM';
export const TOGGLE_CLASS = 'TOGGLE_CLASS';
export const TOGGLE_GROUP = 'TOGGLE_GROUP';
export const SET_SELECTED_ITEM_TREE = 'SET_SELECTED_ITEM_TREE';
// export const TOGGLE_IDROLAB_CLASSIFICATION = 'TOGGLE_IDROLAB_CLASSIFICATION';
// export const RESET_IDROLAB_CLASSIFICATION = 'RESET_IDROLAB_CLASSIFICATION';
export const RESET_SELECTED_TREE_ITEM = 'RESET_SELECTED_TREE_ITEM';
export const FETCH_SECTORS = 'FETCH_SECTORS';
export const FETCH_SECTORS_START = 'FETCH_SECTORS_START';
export const FETCH_SECTORS_SUCCESS = 'FETCH_SECTORS_SUCCESS';
export const FETCH_SECTORS_FAIL = 'FETCH_SECTORS_FAIL';
export const SET_API_HASH = 'SET_API_HASH';
export const SET_CLASSIFICATION_VALUE = 'SET_CLASSIFICATION_VALUE';
export const RESET_CLASSIFICATION = 'RESET_CLASSIFICATION';

export function resetClassification(data){
    return {
        type: RESET_CLASSIFICATION,
        payload: data
    }
}

export function setTree(data) {
    return {
        type: SET_TREE,
        payload: data
    };
}

export function initProductTreeRoots(options) {
    return {
        type: INIT_PRODUCT_TREE_ROOTS,
        payload: options
    };
}

export function toggleTreeItem(id, depth) {
    return {
        type: TOGGLE_TREE_ITEM,
        payload: {
            id,
            depth
        }
    };
}

export function toggleClass(id) {
    return {
        type: TOGGLE_CLASS,
        payload: id
    };
}

/*export function toggleIdrolabClassification(id, type) {
    return {
        type: TOGGLE_IDROLAB_CLASSIFICATION,
        payload: {
            id,
            type
        }
    };
}

export function resetIdrolabClassification() {
    return {
        type: RESET_IDROLAB_CLASSIFICATION
    };
}*/

export function setSelectedItemTree(id) {
    return {
        type: SET_SELECTED_ITEM_TREE,
        payload: id
    };
}

export function toggleGroup(id) {
    return {
        type: TOGGLE_GROUP,
        payload: id
    };
}

export function resetSelectedTreeItem() {
    return {
        type: RESET_SELECTED_TREE_ITEM
    };
}

export function fetchSectors() {
    return {
        type: FETCH_SECTORS
    };
}

export function fetchSectorsStart() {
    return {
        type: FETCH_SECTORS_START
    };
}

export function fetchSectorsSuccess(response) {
    return {
        type: FETCH_SECTORS_SUCCESS,
        payload: response.data
    };
}

export function fetchSectorsFail(response) {
    return {
        type: FETCH_SECTORS_FAIL
        // TODO: portare errore nel payload
    };
}

export function setClassificationValue(value) {
    return {
        type: SET_CLASSIFICATION_VALUE,
        payload: value
    };
}

export function setApiHash(value) {
    return {
        type: SET_API_HASH,
        payload: value
    };
}

export const actionTypes = {
    SET_TREE,
    INIT_PRODUCT_TREE_ROOTS,
    TOGGLE_TREE_ITEM,
    TOGGLE_CLASS,
    TOGGLE_GROUP,
    // TOGGLE_IDROLAB_CLASSIFICATION,
    // RESET_IDROLAB_CLASSIFICATION,
    SET_SELECTED_ITEM_TREE,
    RESET_SELECTED_TREE_ITEM,
    FETCH_SECTORS,
    FETCH_SECTORS_START,
    FETCH_SECTORS_SUCCESS,
    FETCH_SECTORS_FAIL,
    SET_CLASSIFICATION_VALUE,
    SET_API_HASH,
    RESET_CLASSIFICATION
};

export const actions = {
    setTree,
    initProductTreeRoots,
    toggleTreeItem,
    toggleClass,
    toggleGroup,
    // toggleIdrolabClassification,
    setSelectedItemTree,
    resetSelectedTreeItem,
    // resetIdrolabClassification,
    fetchSectors,
    fetchSectorsStart,
    fetchSectorsSuccess,
    fetchSectorsFail,
    setClassificationValue,
    setApiHash
};
