import React, { Component } from 'react';
import { connect } from 'react-redux';

import Sidebar from '../components/Sidebar';
import { selectors } from '../reducers/filtersReducer';
import { selectors as productClassReducerSelectors } from '../reducers/productClassesReducer';
import { selectors as sectorsSelectors } from '../reducers/sectorsReducer';
import { selectors as appSelectors } from '../reducers/appReducer';
import * as constants from '../constants';
import { actions, setFilterValue } from '../actions/filtersActions';
import { actions as sectorsActions } from '../actions/sectorsActions'
import {
    updateLocalStorage,
    getFilterFromLocalStorage,
    removeFilterFromLocalStorage,
    updateLocalStorageWithString
} from '../utils/LocalStorageFilterUtils';
import { actions as productClassesActions } from '../actions/productClassesActions';

function getValidFilterOptions(node) {
    if (node === null) {
        return null;
    }

    if (node.children.size === 0) {
        return null;
    }

    return node.children.map((l) => l.id);
}

export class SidebarContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filtersApplied: {
                status: false,
                line: false,
                model: false,
                series: false,
                listini: false,
                classification: false,
                classification_path: false
            },
            userHasChanged: {
                status: false,
                line: false,
                model: false,
                series: false,
                listini: false,
                classification: false,
                classification_path: false
            }
        }
    }
    // Handle loading the 'fuori_listino_cartaceo' filter from local storage in the componentDidMount event. If the filter is present, it is set and the related SET_FILTER_VALUE action is dispatched.
    componentDidMount() {
        this._isMounted = true  // Set the flag when the component is mounted

        const storedFilters = JSON.parse(localStorage.getItem('userFilters'))

        if (!storedFilters) {
            return;  // Exit if there isn't filters stored in local storage
        }

        const fuoriListinoFromStorage = storedFilters.fuori_listino_cartaceo
        const fuoriCartaceoFilter = this.props.fuoriCartaceoFilter.toJS()

        if (fuoriListinoFromStorage === null || typeof fuoriListinoFromStorage === 'undefined' || !fuoriCartaceoFilter.options) {
            return;  // Exit if the filter fuori_cartaceo isn't stored in local storage
        }

        // Find the options in selector that correspond to the value stored in local storage ( the user choose filter options)
        const storedOption = fuoriCartaceoFilter.options.find(
            (option) => option.value === fuoriListinoFromStorage.toString()
        );

        if (!storedOption || !this._isMounted) {
            return;  // Esce se l'opzione non è trovata o il componente non è montato
        }

        // Dispatch with the complete option value, so with label and so on
        this.props.dispatch({
            type: 'SET_FILTER_VALUE',
            payload: {
                key: constants.FUORI_CARTACEO_FILTER,
                value: storedOption,
            },
        })

    }

    componentDidUpdate(prevProps) {
        // Handling the various filters
        const { statusFilter, lineFilter, modelFilter, seriesFilter, listiniFilter } = this.props;

        const filterMapping = {
            statusFilter: 'stato',
            lineFilter: 'linea',
            modelFilter: 'modello',
            seriesFilter: 'serie',
            listiniFilter: 'listini',
        }

        Object.keys(filterMapping).forEach((filterKey) => {
            const userChangedKey = `userHasChanged.${filterKey.replace('Filter', '')}`;
            const filterAppliedKey = `filtersApplied.${filterKey.replace('Filter', '')}`;

            if (this.state.userHasChanged[filterKey.replace('Filter', '')]) {
                return;  // User changed manually the filter, exit.
            }

            // Check if the filter is change or have some options.
            if (prevProps[filterKey] !== this.props[filterKey] && this.props[filterKey].toJS().options && !this.state.filtersApplied[filterKey.replace('Filter', '')]) {
                const storedFilters = JSON.parse(localStorage.getItem('userFilters'));

                if (!storedFilters || !storedFilters[filterMapping[filterKey]]) {
                    return;  // Return it there aren't stored filters
                }

                const filterFromStorage = storedFilters[filterMapping[filterKey]];
                const filterProperties = this.props[filterKey].toJS();

                filterFromStorage.forEach(storedValue => {
                    const matchedOption = filterProperties.options.find(option => option.value === storedValue);
                    if (matchedOption) {
                        console.log('sto eseguendo la set filter value da SidebarContainer e fromInternalPage è => ', this.props.fromInternalPage)
                        matchedOption.enabled = true

                        if(!this.props.fromInternalPage){
                            this.props.dispatch({
                                type: 'SET_FILTER_VALUE',
                                payload: {
                                    key: constants[`${filterKey.replace('Filter', '').toUpperCase()}_FILTER`],
                                    value: matchedOption,
                                },
                            })
                        }

                    }
                });

                // Set the state to avoid ulteriori filters updates.
                this.setState((prevState) => ({
                    filtersApplied: {
                        ...prevState.filtersApplied,
                        [filterKey.replace('Filter', '')]: true
                    }
                }));
            }
        })

        // Handling the Classifications
        const classificationMapping = {
            classificationFilter: 'product_tree',
        }

        Object.keys(classificationMapping).forEach((filterKey) => {
            const userChangedKey = `userHasChanged.${filterKey.replace('Filter', '')}`;
            const filterAppliedKey = `filtersApplied.${filterKey.replace('Filter', '')}`;

            if (this.state.userHasChanged[filterKey.replace('Filter', '')]) {
                return  // User changed manually the filter, exit.
            }

            if (prevProps[filterKey] !== this.props[filterKey] && this.props[filterKey].toJS().options && !this.state.filtersApplied[filterKey.replace('Filter', '')]) {
                const storedFilters = JSON.parse(localStorage.getItem('userFilters'));

                if (!storedFilters || !storedFilters[filterMapping[filterKey]]) {
                    return;  // Return it there aren't stored filters
                }

                const filterFromStorage = storedFilters[filterMapping[filterKey]];
                const filterProperties = this.props[filterKey].toJS();
            }
        })

        // Here we write the code for classification_path, to set the state of classification path: is the selectedItem in SectorsTree
        const classificationPath = getFilterFromLocalStorage('classification_path')
        // console.log('this.state.userHasChanged.classification_path', this.state.userHasChanged.classification_path)

        // Early return se l'utente ha cambiato manualmente il classification_path o se è già stato applicato
        if (this.state.userHasChanged.classification_path || this.state.filtersApplied.classification_path) {
            return;
        }
        // if (classificationPath && !this.state.filtersApplied.classification_path) {
        if (classificationPath) {

            // Dispatch dell'azione TOGGLE_TREE_ITEM
            this.props.dispatch({
                type: 'TOGGLE_TREE_ITEM',
                payload: {
                    id: classificationPath,
                    depth: 0
                }
            });

            // Aggiornamento dello stato interno
            this.setState((prevState) => ({
                filtersApplied: {
                    ...prevState.filtersApplied,
                    classification_path: true,  // Segna il filtro come applicato
                },
                userHasChanged: {
                    ...prevState.userHasChanged,
                    classification_path: false  // Indica che non è stato cambiato manualmente
                }
            }));
        }
    }

    handleFilterChange = (filterKey, selectedFilter) => {
        //console.log('handleFilterChange', filterKey, selectedFilter)

        if (selectedFilter === null) {
            removeFilterFromLocalStorage(filterKey)
            this.updateStateAndDispatch(filterKey, null)
            return
        }

        const currentStoredFilter = getFilterFromLocalStorage(filterKey);
        const action = currentStoredFilter && currentStoredFilter.includes(selectedFilter.value)
            ? 'remove'
            : 'add'

        if(filterKey === 'classification' || filterKey === 'classification_path') {
            updateLocalStorageWithString(filterKey, selectedFilter.value, action)
        }else{
            updateLocalStorage(filterKey, selectedFilter.value, action)
        }

        if(filterKey === 'classification'){
            updateLocalStorageWithString('classification_path', '', 'remove')
        }

        this.updateStateAndDispatch(filterKey, selectedFilter)
    }

    // Helper function to update State and dispatch action
    updateStateAndDispatch = (filterKey, selectedFilter) => {
        this.setState((prevState) => ({
            filtersApplied: {
                ...prevState.filtersApplied,
                [filterKey]: false,  // Reset when user manually changes the filter
            },
            userHasChanged: {
                ...prevState.userHasChanged,
                [filterKey]: true,  // Mark as manually changed
            }
        }))

        if(filterKey === 'classification'){
            // console.log('we are changing a classification => ', selectedFilter)
            this.props.dispatch({
                type: 'SET_CLASSIFICATION_VALUE',
                payload: selectedFilter,
            })

            return
        }

        if (filterKey === 'classification_path') {
            this.props.dispatch({
                type: 'TOGGLE_TREE_ITEM',
                payload: {
                    id: selectedFilter.id,
                    depth: selectedFilter.depth,
                },
            });
            return
        }

        this.props.dispatch({
            type: 'SET_FILTER_VALUE',
            payload: {
                key: constants[`${filterKey.toUpperCase()}_FILTER`],
                value: selectedFilter,
            },
        })
    }


    render() {
        const filterMapping = {
            onStatusChange: 'status',
            onLineChange: 'line',
            onModelChange: 'model',
            onSeriesChange: 'series',
            onListiniChange: 'listini',
            onClassificationChange: 'classification',
            onClassificationPathChange: 'classification_path',
        }

        const sidebarProps = Object.keys(filterMapping).reduce((acc, eventKey) => {
            const filterKey = filterMapping[eventKey];
            acc[eventKey] = (selectedFilter) => this.handleFilterChange(filterKey, selectedFilter);
            return acc;
        }, {})
        //console.log(sidebarProps)
        return <Sidebar {...this.props} {...sidebarProps} />
    }
}

function mapStateToProps(state) {
    const productProperties = appSelectors.getProductProperties(state);

    const classificationType = sectorsSelectors.getFilterValue(state);
    let selectedNode = null;

    if (classificationType === constants.ETIM_CLASSIFICATION) {
        selectedNode = sectorsSelectors.getSelectedTreeItemRecord(state);
    }

    // console.log('selectors.getFilter(state, constants.LINE_FILTER),', selectors.getFilter(state, constants.LINE_FILTER).toJS())

    return {
        classificationFilter: sectorsSelectors.getFilter(state),
        brandFilter: selectors.getFilter(state, constants.BRAND_FILTER),
        // listiniFilter: selectors.getFilter(state, constants.LISTINI_FILTER),
        // optionalsFilter: selectors.getFilter(state, constants.OPTIONALS_FILTER),
        priceRangeFilter: selectors.getFilter(state, constants.PRICE_RANGE_FILTER),
        isBrandFixed: appSelectors.getIsBrandFixed(state),
        language: appSelectors.getLanguage(state),
        showPriceRangeFilter: !!productProperties.prezzo_listino,
        // outOfProductionFilter: selectors.getFilter(
        //     state,
        //     constants.OUT_OF_PRODUCTION_FILTER
        // ),
        // goingOutOfProductionFilter: selectors.getFilter(
        //     state,
        //     constants.GOING_OUT_OF_PRODUCTION_FILTER
        // ),
        // replacementsFilter: selectors.getFilter(state, constants.REPLACEMENTS_FILTER),
        statusFilter: selectors.getFilter(state, constants.STATUS_FILTER),
        lineFilter: selectors.getFilter(state, constants.LINE_FILTER),
        modelFilter: selectors.getFilter(state, constants.MODEL_FILTER),
        seriesFilter: selectors.getFilter(state, constants.SERIES_FILTER),
        listiniFilter: selectors.getFilter(state, constants.LISTINI_FILTER),
        fuoriCartaceoFilter: selectors.getFilter(state, constants.FUORI_CARTACEO_FILTER),
        esportabilePerCatalogoFilter: selectors.getFilter(
            state,
            constants.ESPORTABILE_PER_CATALOGO_FILTER
        ),
        productClassFilter: productClassReducerSelectors.getFilter(state),
        //selectedItem: sectorsSelectors.getSelectedItem(state),
        enableEsportabilePerCatalogoFilter:
            appSelectors.getEnableEsportabilePerCatalogoFilter(state),
        validFilterOptions: getValidFilterOptions(selectedNode),
    };
}

export default connect(mapStateToProps)(SidebarContainer);
