// src/utils/LocalStorageFilterUtils.js
const filterMap = {
    status: 'stato',
    line: 'linea',
    model: 'modello',
    series: 'serie',
    brand: 'sigle_marca',
    prezzo_listino_from: 'prezzo_listino_from',
    prezzo_listino_to: 'prezzo_listino_to',
    classification: 'product_tree'
    // Add more maps if needed
}

const getMappedFilterKey = (key) => {
    return filterMap[key] || key;
}

export const isLocalStorageAvailable = () => {
    try {
        if (typeof localStorage === 'undefined') {
            return false;
        }

        const storedFilters = localStorage.getItem('userFilters');
        return storedFilters !== null;
    } catch (error) {
        console.error('Error accessing localStorage:', error);
        return false;
    }
}
export const initializeLocalStorage = (apiPayload) => {
    try {
        if (typeof localStorage === 'undefined') {
            console.error('localStorage is not available.');
            return;
        }

        // Check if 'userFilters' already exists in localStorage
        const storedFilters = localStorage.getItem('userFilters');

        if (storedFilters === null) {
            // If userFilters doesn't exist, initialize it with apiPayload
            localStorage.setItem('userFilters', JSON.stringify(apiPayload));
            console.log('userFilters initialized with apiPayload.');
        } else {
            console.log('userFilters already exists.');
        }

    } catch (error) {
        console.error('Error initializing localStorage:', error);
    }
}
export const clearUserFilters = () => {
    try {
        if (typeof localStorage === 'undefined') {
            console.error('localStorage is not available.');
            return;
        }

        // Remove 'userFilters' from localStorage
        localStorage.removeItem('userFilters');
        console.log('userFilters cleared from localStorage.');
    } catch (error) {
        console.error('Error clearing userFilters from localStorage:', error);
    }
};

export const updateLocalStorage = (key, value, action = 'add') => {
    const mappedKey = getMappedFilterKey(key)
    const storedFilters = JSON.parse(localStorage.getItem('userFilters')) || {};

    if (action === 'add') {
        if (Array.isArray(storedFilters[mappedKey])) {
            // If array already have this value, we don't add avoiding duplicates
            if (!storedFilters[mappedKey].includes(value)) {
                storedFilters[mappedKey].push(value);
            }
        } else {
            // If isn't an array, we create an array with that value
            storedFilters[mappedKey] = [value];
        }
    } else if (action === 'remove') {
        if (Array.isArray(storedFilters[mappedKey])) {
            // Remove the value from array
            storedFilters[mappedKey] = storedFilters[mappedKey].filter(item => item !== value);

            if (storedFilters[mappedKey].length === 0) {
                delete storedFilters[mappedKey];
            }
        } else {
            delete storedFilters[mappedKey];
        }
    }

    // Add or remove item from localStorage
    if (Object.keys(storedFilters).length === 0) {
        localStorage.removeItem('userFilters');
    } else {
        localStorage.setItem('userFilters', JSON.stringify(storedFilters));
    }
}

export const updateLocalStorageWithString = (key, value, action = 'add') => {
    const mappedKey = getMappedFilterKey(key);
    const storedFilters = JSON.parse(localStorage.getItem('userFilters')) || {};

    if (action === 'add') {
        // Set the string value
        storedFilters[mappedKey] = value;
    } else if (action === 'remove') {
        // Remove the key if it exists
        if (storedFilters[mappedKey]) {
            delete storedFilters[mappedKey];
        }
    }

    // Update or remove from localStorage based on storedFilters content
    if (Object.keys(storedFilters).length === 0) {
        localStorage.removeItem('userFilters');
    } else {
        localStorage.setItem('userFilters', JSON.stringify(storedFilters));
    }
}

export const getFilterFromLocalStorage = (key) => {
    const mappedKey = getMappedFilterKey(key)
    const storedFilters = JSON.parse(localStorage.getItem('userFilters')) || {};
    return storedFilters[mappedKey] || null;
}

export const removeFilterFromLocalStorage = (key) => {
    const mappedKey = getMappedFilterKey(key)
    const storedFilters = JSON.parse(localStorage.getItem('userFilters')) || {};

    if (storedFilters[mappedKey]) {
        // Remove the property from the localStorage
        delete storedFilters[mappedKey];

        // If there aren't properties in storedFilters, I remove the entire record
        if (Object.keys(storedFilters).length === 0) {
            localStorage.removeItem('userFilters');
        } else {
            localStorage.setItem('userFilters', JSON.stringify(storedFilters));
        }
    }
}

export const removeBrandRelatedFilterFromLocalStorage = (key = 'all') => {
    if(key === 'all'){
        removeFilterFromLocalStorage('line')
        removeFilterFromLocalStorage('model')
        removeFilterFromLocalStorage('series')
    }
}
export const clearLocalStorage = () => {
    localStorage.removeItem('userFilters');
};
