import React, { Component } from 'react';
// import { Resizable } from 're-resizable';
import { connect } from 'react-redux';
import ContainerDimensions from 'react-container-dimensions';
import cx from 'classnames';
import qs from 'query-string';

import Sidebar from '../containers/Sidebar';
import ArticleTable from '../containers/ArticleTable';
import ArticleDetailsModal from './ArticleDetailsModal';
import { selectors as appSelectors } from '../reducers/appReducer';
import { toggleSidebar } from '../actions/appActions';
import { fetchItems } from '../actions/itemsActions';
import { loadFiltersFromLocalStorage } from '../actions/filtersActions'; // Import the action to load filters


// function getMinSidebarWidth(windowWidth) {
//     const w = Math.floor(windowWidth / 5);

//     if (w > 250) {
//         return w;
//     }

//     return 250;
// }

// function getMaxSidebarWidth(windowWith) {
//     const w = Math.floor(windowWith / 3);

//     if (w > 0) {
//         return w;
//     }

//     return 400;
// }

export class DataPoolComponent extends Component {
    state = {
        showArticleModal: false,
    };

    componentDidMount() {
        const search = qs.parse(window.location.search)

        const isFromInternalPage = this.props.fromInternalPage
        // console.log('DATAPOOL - isFromInternalPage', isFromInternalPage)
        // Load filters from localStorage when the component mounts
        this.props.dispatch(loadFiltersFromLocalStorage())
        // this.props.dispatch(fetchItems());

        if (search.showArticle) {
            this.setState({
                showArticleModal: search.showArticle,
            });

            // TODO: replace url per eliminare querystring?
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.fromInternalPage !== this.props.fromInternalPage) {
            // console.log('fromInternalPage has changed:', this.props.fromInternalPage);
            this.props.dispatch(loadFiltersFromLocalStorage())
        }
    }

    render() {
        const { /*width,*/ isSidebarOpen, toggleSidebar } = this.props;

        return (
            <div className="flex">
                {/* <Resizable
                    defaultSize={{
                        width: getMinSidebarWidth(width),
                        height: 'auto',
                    }}
                    minWidth={getMinSidebarWidth(width)}
                    maxWidth={getMaxSidebarWidth(width)}
                    enable={{
                        top: false,
                        right: true,
                        bottom: false,
                        left: false,
                        topRight: false,
                        bottomRight: false,
                        bottomLeft: false,
                        topLeft: false,
                    }}
                > */}
                <ContainerDimensions>
                    {({ height, width }) => {
                        return (
                            <div
                                className={cx(
                                    'sidebar-area px-2 fixed lg:relative w-5/6 lg:w-3/12 z-10 lg:z-auto pt-1 bg-gray-200 lg:bg-white',
                                    {
                                        hidden: !isSidebarOpen && width < 1024,
                                    }
                                )}
                            >
                                <Sidebar height={height} fromInternalPage={this.props.fromInternalPage}/>
                            </div>
                        );
                    }}
                </ContainerDimensions>
                <div
                    onClick={toggleSidebar}
                    className={cx(
                        'lg:hidden z-[1] bg-slate-600 opacity-50 w-full h-screen absolute',
                        {
                            hidden: !isSidebarOpen,
                        }
                    )}
                />
                {/* </Resizable> */}
                <div
                    className="px-1 overflow-y-auto flex flex-col flex-1 w-full mt-1"
                    style={{ overflow: 'hidden' }}
                >
                    <ArticleTable />
                </div>
                {this.state.showArticleModal && (
                    <ArticleDetailsModal
                        article={{ id: this.state.showArticleModal }}
                        onClose={() => this.setState({ showArticleModal: false })}
                        containerId={`article-detail-modal-${this.state.showArticleModal}`}
                    />
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        isSidebarOpen: appSelectors.getIsSidebarOpen(state),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        toggleSidebar() {
            dispatch(toggleSidebar());
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DataPoolComponent);
